// import components
import Pages from "./Pages";
import AOS from "aos";
import "aos/dist/aos.css";
import { Route, Routes } from "react-router-dom";
AOS.init();

function App() {
  return (
    <Routes>
      <Route path="*" element={<Pages />} />
    </Routes>
  );
}

export default App;

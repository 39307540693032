import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FooterEnd from "./FooterEnd";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function Footer() {
  const { t } = useTranslation();

  const [send, setSend] = useState(false);

  const SendMessage = (e) => {
    e.preventDefault();
    setSend(true);
    setTimeout(() => {
      setSend(false);
    }, 2000);
    let message = `👤Ism Familiya: ${e.target[0].value} %0A
    ☎️Telefon Raqam: ${value}`;
    const token = "5624199540:AAHblZ4MhCVlbEt7Z1p9KxqiyRBpvZYKyAE";
    let url =
      "https://api.telegram.org/bot" +
      token +
      "/sendMessage?chat_id=-1001676772279&text=";
    let xhttp = new XMLHttpRequest();
    xhttp.open("GET", url + message, true);
    xhttp.send();

    e.target.reset();
  };

  const [value, setValue] = useState();

  return (
    <>
      <Wrapper id="call">
        <div className="container">
          <div className="flex mobile-column">
            <div className="text">
              <h1>{t("question")}</h1>
              <p>{t("contact")}</p>
            </div>

            <div className="input-group">
              <form onSubmit={(e) => SendMessage(e)}>
                <div className="flex column">
                  <input
                    required
                    type="text"
                    data-aos="fade-up"
                    placeholder={t("name")}
                  />
                  {/* <input required type="tell" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder={t("phonenumber")} /> */}

                  <PhoneInput
                    data-aos="fade-up"
                    country="UZ"
                    value={value}
                    placeholder={t("phonenumber")}
                    onChange={setValue}
                  />

                  <button type="submit" data-aos="fade-up">
                    {t("send")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className={send ? "toast active" : "toast"}>
          <div className="toast-content">
            <i className="fas fa-solid fa-check check"></i>

            <div className="message">
              <span className="text text-1" data-aos="fade-up">
                {t("sendH")}
              </span>
              <span className="text text-2" data-aos="fade-up">
                {t("sendM")}
              </span>
            </div>
          </div>
          <i
            data-aos="fade-up"
            onClick={() => setSend(false)}
            className="fa-solid fa-xmark close"
          ></i>

          <div className="progress" data-aos="fade-up"></div>
        </div>
      </Wrapper>

      <FooterEnd />
    </>
  );
}

export default Footer;

const Wrapper = styled.footer`
  background-color: #00094c;
  padding: 30px 0 90px 0;
  position: relative;
  * {
    color: white;
  }
  @media (max-width: 768px) {
    padding: 0 0 50px 0 !important;
  }
  .flex {
    max-width: 1100px;
    margin: 0 auto;
    padding-top: 100px;
    gap: 100px;

    @media (max-width: 768px) {
      row-gap: 40px;
      padding-top: 40px;
    }
    .text {
      max-width: 600px;
      h1 {
        font-size: 60px;
      }
      p {
        color: #c8c6c6;
        font-size: 17px;
      }
    }
    .input-group {
      .flex {
        padding: 0;
        gap: 0;
      }
      input {
        padding: 15px 70px 15px 70px;
        border-radius: 20px;
        margin-bottom: 20px;
        background-color: transparent;
        border: 1px solid white;
        outline: none;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        &::placeholder {
          color: #ffffff;
          letter-spacing: 1px;
        }
        &:focus {
          outline: red;
        }
      }
      .PhoneInputCountry {
        display: none;
      }
      button {
        background-color: white;
        color: #00094c;
        font-size: 20px;
        width: 100%;
        padding: 15px 40px !important;
        font-weight: 900;
        font-family: sans-serif;
        border-radius: 20px;
      }
    }
  }
`;

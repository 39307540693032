import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import wide from "../images/wide-logo.png";

function About() {
  const { t } = useTranslation();

  return (
    <Wrapper className="container flex align-center mobile-column-reverse">
      <img src={wide} data-aos="fade-right" alt="" />
      <main className="bottom" id="about">
        <h1 data-aos="fade-up">{t("bizhaqimizda")}</h1>
        <div className="devider half"></div>
        <p data-aos="fade-up">
          {t("about-info")}
          <br />
          <br />
        </p>
        <p data-aos="fade-up">
          <b>{t("about-bold")}</b>
          {t("about-info2")}
        </p>
        <div className="devider half"></div>
        <a data-aos="fade-right" href="#services" className="button large">
          {t("xizmatlar")}
        </a>
      </main>
    </Wrapper>
  );
}

export default About;
const Wrapper = styled.div`
  gap: 80px;
  img {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 500px) {
    * {
      text-align: center;
    }
    img {
      display: none;
    }
  }
  main {
    p {
      font-size: 22px;
      font-weight: 500;
    }
  }
`;

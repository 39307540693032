import React from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import header from "../images/header.png";

import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Navbar />
      <main className="container flex align-center justify-between mobile-column">
        <div className="flex column">
          <small data-aos="fade-up">{t("companyname")}</small>
          <h1 data-aos="fade-up" data-aos-delay="200">
            {t("header")}
          </h1>
          <p data-aos="fade-up" data-aos-delay="500">
            {t("agentlik")}
          </p>
          <div className="flex align-center justify-center buttons mobile-column ">
            <a data-aos="fade-down" href="#services" className="secondary button">
              {t("xizmatlar")}
            </a>
            <a data-aos="fade-down" href="#call" className="button">
              {t("boglanish")}
            </a>
          </div>
        </div>
        <div className="images">
          <img className="top" src={header} alt="header" />
        </div>
      </main>
    </Wrapper>
  );
}

export default Header;
const Wrapper = styled.header`
  background: linear-gradient(#d9e9ff, #e5f0ff);

  main {
    padding-top: 10px;
    padding-bottom: 100px;
    small {
    }
    @media (max-width: 900px) {
      flex-direction: column;
      padding-top: 70px;
      & > div:first-of-type {
        gap: 20px !important;
      }
      small {
        line-height: 10px;
      }
    }
    @media (max-width: 768px) {
      padding-top: 40px;
    }
    & > div:first-of-type {
      gap: 30px;
    }
    h1 {
      max-width: 900px;
    }
    .buttons {
      gap: 30px;
      width: fit-content;

      @media (max-width: 768px) {
        margin: 0 auto;
        gap: 15px;
      }
      @media (max-width: 500px) {
        a {
          width: 150%;
        }
      }
    }
    .images {
      max-width: 680px;
      margin-top: 40px;
      animation: downImg 5s linear infinite;
      img {
        width: 100%;
        background-size: cover;
        background-position: center;
      }
    }
    @keyframes downImg {
      50% {
        transform: translateY(-30px);
      }
    }
  }
`;

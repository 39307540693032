import React from "react";

import About from "./components/About";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Portfolio from "./components/Portfolio";
import Results from "./components/Results";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";

function Pages() {
  const hash = window.location.hash;
  if (hash) {
    window.location.hash = "";
    setTimeout(() => {
      window.location.hash = hash;
    }, 300);
  }

  return (
    <>
      <Header />
      <div className="devider"></div>
      <About />
      <div className="devider"></div>
      <Results />
      <div className="devider"></div>
      <Services />
      <div className="devider"></div>
      <Portfolio />
      <div className="devider"></div>
      <Testimonials />
      <div className="devider"></div>
      <Footer />
    </>
  );
}

export default Pages;

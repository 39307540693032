import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import logo1 from "../images/logos/Image-1.png";
import logo2 from "../images/logos/Image-2.png";
import logo3 from "../images/logos/Image-3.png";
import logo4 from "../images/logos/Image-4.png";
import logo5 from "../images/logos/Image-5.png";
import logo6 from "../images/logos/Image-6.png";
import logo7 from "../images/logos/Image-7.png";
import logo8 from "../images/logos/Image-8.png";
import logo9 from "../images/logos/Image-9.png";
import logo10 from "../images/logos/Image-10.png";
import logo11 from "../images/logos/Image-11.png";
import logo12 from "../images/logos/Image-12.png";
import logo13 from "../images/logos/Image-13.png";
import logo14 from "../images/logos/Image-14.png";
import logo15 from "../images/logos/Image-15.png";
import logo16 from "../images/logos/Image-10.png";

import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

function Portfolio() {
  const { t } = useTranslation();
  const [custom_swiper, set_swiper] = useState({});
  SwiperCore.use([Autoplay]);
  setTimeout(() => {
    set_swiper(custom_swiper);
  }, []);

  return (
    <Wrapper id="portfolio" className="container">
      <h2 className="text-center" data-aos="fade-up">
        {t("portfolio")}
      </h2>
      <main>
        <Swiper
          data-aos="fade-up"
          className="swiper-slides"
          spaceBetween={10}
          slidesPerView={2}
          speed={10000}
          autoplay={{
            duration: 0,
            delay: 2500,
          }}
          loop={true}
          onInit={(ev) => {
            set_swiper(ev);
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            // when window width is >= 768px
            868: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide className="slide">
            <img src={logo1} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo2} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo3} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo4} alt="" />
          </SwiperSlide>
        </Swiper>
        <Swiper
          data-aos="fade-up"
          className="swiper-slides"
          spaceBetween={10}
          slidesPerView={2}
          speed={10000}
          autoplay={{
            duration: 0,
            delay: 1000,
          }}
          loop={true}
          onInit={(ev) => {
            set_swiper(ev);
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            // when window width is >= 768px
            868: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide className="slide">
            <img src={logo5} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo6} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo7} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo8} alt="" />
          </SwiperSlide>
        </Swiper>
        <Swiper
          data-aos="fade-up"
          className="swiper-slides"
          spaceBetween={10}
          slidesPerView={2}
          speed={10000}
          autoplay={{
            duration: 0,
            delay: 0,
          }}
          loop={true}
          onInit={(ev) => {
            set_swiper(ev);
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            // when window width is >= 768px
            868: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide className="slide">
            <img src={logo9} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo10} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo11} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo12} alt="" />
          </SwiperSlide>
        </Swiper>
        <Swiper
          data-aos="fade-up"
          className="swiper-slides"
          spaceBetween={10}
          slidesPerView={2}
          speed={10000}
          autoplay={{
            duration: 0,
            delay: 2000,
          }}
          loop={true}
          onInit={(ev) => {
            set_swiper(ev);
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            // when window width is >= 768px
            868: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide className="slide">
            <img src={logo13} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo14} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo15} alt="" />
          </SwiperSlide>
          <SwiperSlide className="slide">
            <img src={logo16} alt="" />
          </SwiperSlide>
        </Swiper>
      </main>
    </Wrapper>
  );
}

export default Portfolio;

const Wrapper = styled.div`
  main {
    width: 100%;
    padding: 50px 0;

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }

    .swiper-slides {
      margin-bottom: 70px;
      @media (max-width: 768px) {
        margin-bottom: 40px;
      }
      .slide {
        cursor: grab;
        img {
          margin: 0 auto;
          display: block;
          height: 80px;
          background-size: cover;
          @media (max-width: 768px) {
            height: 50px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";

function Results() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="container">
        <h2 className="text-center">{t("result")}</h2>
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="grid-container">
              <div className="card" data-aos="fade-up">
                <span>
                  + {isVisible ? <CountUp end={30} enableScrollSpy /> : 0}
                </span>
                <p>{t("loyihalar")}</p>
              </div>
              <div className="card" data-aos="fade-up">
                <span>
                  + {isVisible ? <CountUp end={25} enableScrollSpy /> : 0}
                </span>
                <p>{t("smm")}</p>
              </div>
              <div className="card" data-aos="fade-up">
                <span>
                  + {isVisible ? <CountUp end={5} enableScrollSpy /> : 0}
                </span>
                <p>{t("branding")}</p>
              </div>
            </div>
          )}
        </VisibilitySensor>
      </div>
    </Wrapper>
  );
}

export default Results;

const Wrapper = styled.div`
  width: 100%;
  background: #f8f8f8;
  padding: 100px 0;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
  .container {
    .grid-container {
      display: grid;
      grid-template-columns: auto auto auto;
      margin-top: 100px;
      height: 300px;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      @media (max-width: 1300px) {
        grid-template-columns: auto auto;
        justify-content: center;
        height: auto !important;
        gap: 40px;
      }
      @media (max-width: 970px) {
        grid-template-columns: auto;
        justify-content: center;
        margin-top: 50px;
        .card {
          width: 300px;
        }
      }
      .card {
        height: 280px;
        width: 420px;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        border: 1px solid #f2f2f296;
        background: white;
        justify-content: center;
        transition: 0.3s;
        @media (max-width: 480px) {
          width: 380px;
        }
        @media (max-width: 400px) {
          width: 350px;
        }
        @media (max-width: 380px) {
          width: 330px;
        }
        span {
          color: #00094c;
          text-align: center;
          font-size: 100px;
          display: flex;
          font-weight: 700;
          line-height: 110px;
          margin: 0 auto;
        }
        p {
          font-size: 20px;
          text-align: center;
          font-weight: 500;
          color: #00094c;
        }

        &:hover {
          cursor: pointer;
          transform: translateY(-10px);
        }
      }
    }
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import SlideTop from "./SlideTop";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Testimonial from "./Testimonial";
import img1 from "../images/user1.png";
import img2 from "../images/user2.png";
import img3 from "../images/user3.png";

import { useTranslation } from "react-i18next";
import SlideBottom from "./SlideBottom";

function Testimonials() {
  const { t } = useTranslation()
  const [custom_swiper, set_swiper] = useState({});
  const nextSlide = () => {
    custom_swiper.slideNext();
  };
  const prevSlide = () => {
    custom_swiper.slidePrev();
  };
  return (
    <Wrapper id="testimonials" className="container">
      <h2 className="text-center">{t("mijozlarfikri")}</h2>
      <SlideTop
        next={nextSlide}
        prev={prevSlide}
      />
      <div className="devider mobile-none"></div>
      <main>
        <Swiper className="swiper-slides"
          spaceBetween={20}
          slidesPerView={1}
          loop
          onInit={(ev) => {
            set_swiper(ev);
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
            },
            // when window width is >= 768px
            868: {
              slidesPerView: 3,
            },
          }}
        >
          <SwiperSlide>
            <Testimonial
              name={t("u1")}
              job={t("u1job")}
              img={img1}
              description={t("user1")}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Testimonial
              name={t("u2")}
              job={t("u2job")}
              img={img2}
              description={t("user2")}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Testimonial
              name={t("u3")}
              job={t("u3job")}
              img={img3}
              description={t("user3")}
            />
          </SwiperSlide>
        </Swiper>
      </main>

      <SlideBottom
        next={nextSlide}
        prev={prevSlide}
      />
    </Wrapper>
  );
}

export default Testimonials;
const Wrapper = styled.div`
.swiper-slides {
  padding: 20px 0;
  overflow-y: visible;
}
@media (max-width: 768px) {
  .mobile-none {
    padding: 10px 0;
  }
}
`;

import React from "react";
import styled from "styled-components";
import "swiper/css";
import smm from "../images/smm.png";
import web from "../images/website.png";
import branding from "../images/branding.png";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();

  const cards = [
    {
      img: smm,
      tittle: "SMM",
      info: t("card1"),
    },
    {
      img: web,
      tittle: "Web",
      info: t("card3"),
    },
    {
      img: branding,
      tittle: "Branding",
      info: t("card2"),
    },
  ];

  return (
    <Wrapper className="container" id="services">
      <h2 className="text-center">{t("xizmatlarimiz")}</h2>
      <div className="devider md"></div>
      <div className="grid-container">
        {cards.map((i) => {
          return (
            <div className="card" data-aos="fade-up">
              <img src={i.img} alt="img" />
              <h3>{i.tittle}</h3>
              <p>{i.info}</p>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

export default Services;

const Wrapper = styled.div`
  .grid-container {
    display: grid;
    /* grid-template-columns:  repeat(auto-fill, minmax(300px, 1fr));  */
    grid-template-columns: auto auto auto;
    margin-top: 100px;
    height: 300px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @media (max-width: 1300px) {
      grid-template-columns: auto auto;
      justify-content: center;
      height: auto !important;
      gap: 40px;
    }
    @media (max-width: 970px) {
      grid-template-columns: auto;
      justify-content: center;
      margin-top: 0;
      .card {
        width: 300px;
      }
    }
    .card {
      height: 290px;
      width: 420px;
      display: flex;
      flex-direction: column;
      border-radius: 15px;
      justify-content: center;
      padding-bottom: 20px;
      background: linear-gradient(0deg, #00094c, #00094c),
        linear-gradient(
          0deg,
          rgba(242, 242, 242, 0.59),
          rgba(242, 242, 242, 0.59)
        );
      transition: all 0.4s;
      transition: 0.3s;
      @media (max-width: 480px) {
        width: 380px;
        p {
          padding: 0 45px !important;
        }
      }
      @media (max-width: 400px) {
        width: 350px;
        p {
          padding: 0 35px !important;
        }
      }
      @media (max-width: 380px) {
        width: 330px;
      }
      * {
        color: #e5e5e5;
        text-align: center;
      }
      img {
        width: 138px;
        height: 138px;
        background-size: cover;
        margin: 0 auto;
        transition: all 0.2s;
      }
      h3 {
        font-size: 35px;
        font-weight: 700;
        line-height: 50px;
      }
      p {
        font-size: 14px;
        line-height: 22.5px;
        font-weight: 400;
        padding: 0 70px;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";
import q from "../images/quote.png";

function Testimonial({ img, name, description, job }) {
  return (
    <Wrapper>
      <p className="sm" data-aos="fade-up">
        {description}
      </p>
      <div className="devider half end"></div>
      <div className="author" data-aos="fade-up">
        <div className="line"></div>
        <div className="flex align-center">
          <img src={img} alt="" />
          <div className="content">
            <p className="line-text">{name}</p>
            <p className="line-text">{job}</p>
          </div>
        </div>
      </div>
      <img src={q} alt="" className="quote" />
    </Wrapper>
  );
}

export default Testimonial;
const Wrapper = styled.div`
  position: relative;
  height: 300px;
  margin: 0 auto;
  width: 410px;
  max-width: 100%;
  cursor: -webkit-grab;
  cursor: grab;
  padding: 50px 50px 24px 50px;
  position: relative;
  background: #ffffff;
  border: 1px solid #f2f2f296;
  box-shadow: 8px 11px 42px 0px #0000000a;
  border-radius: 15px;
  overflow: hidden;
  box-sizing: border-box;

  @media (max-width: 420px) {
    width: 350px;
    padding: 30px 20px 20px 20px;
  }

  p.sm {
    font-size: 14px;
  }
  p,
  div {
    z-index: 2;
    position: relative;
    font-size: 14px;
  }
  .author {
    border-top: 1px solid #f2f2f296;
    position: absolute;
    bottom: 20px;
    width: 310px;
    @media (max-width: 420px) {
    }
    .flex {
      gap: 25px;
      margin-top: 16px;
      p:last-of-type {
        font-size: 11px;
        margin-top: -5px;
      }
    }
  }
  .quote {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

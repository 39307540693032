import React from "react";
import styled from "styled-components";

function SlideBottom({ next, prev }) {
  return (
    <Wrapper className="arrows flex align-center justify-between">
      <button
        data-aos="fade-up"
        onClick={prev}
        className="light flex align-center justify-center slide-next"
      >
        <svg
          width="21"
          height="32"
          viewBox="0 0 21 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 29L3 16L18 3"
            stroke="#D9D9D9"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button
        data-aos="fade-up"
        onClick={next}
        className="flex align-center justify-center slide-next"
      >
        <svg
          width="21"
          height="32"
          viewBox="0 0 21 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotateY(180deg)" }}
        >
          <path
            d="M18 29L3 16L18 3"
            stroke="white"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </Wrapper>
  );
}

export default SlideBottom;

const Wrapper = styled.aside`
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: flex;
    width: 140px;
    margin: 0 auto;
    gap: 15px;
    button {
      width: 60px;
      display: flex;
      height: 60px;
      border-radius: 15px;
      svg {
        width: 100%;
        flex: 0 0 15px;
      }
    }
  }
`;

import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import logo from "../images/logo.svg"


function FooterEnd() {

    const { t } = useTranslation()

    return (
        <FoterEnd className="container">
            <div className="grid">
                <div className="links" data-aos="fade-up">
                    <img src={logo} alt="logo" />
                    <p className='info'>{t("insan-info")}</p>
                </div>

                <div className="links flex column" data-aos="fade-up">
                    <h4>{t("havolalar")}</h4>
                    <a href="#about">{t("bizhaqimizda")}</a>
                    <a href="#services">{t("xizmatlar")}</a>
                    <a href="#portfolio">{t("portfolio")}</a>
                    <a href="#testimonials">{t("mijozlar-fikri")}</a>
                    <a href="#call">{t("aloqa")}</a>
                </div>

                <div className="links flex column" data-aos="fade-up">
                    <h4>{t("manzil")}</h4>
                    <a href="#locate">{t("locatsiya")}</a>
                    <a href="tel:+998 99 4466767">Tel: +998 99 4466767</a>
                    <a href="https://t.me/insanmarketing">{t("tg")}: @insanmarketing</a>
                    <a href="https://www.instagram.com/insanmarketing">{t("insta")}: @insanmarketing</a>
                    <a href="https://www.facebook.com/Insanmarketing">{t("face")}: @insanmarketing</a>
                </div>
            </div>
        </FoterEnd>
    )
}

export default FooterEnd

const FoterEnd = styled.footer`
    padding: 50px 60px;

    @media (max-width: 768px) {
        padding: 40px 20px ;
    }
    .grid {
        display: grid;
        grid-template-columns:  repeat(auto-fill, minmax(300px, 1fr)); 
        gap: 40px;

        .links:nth-child(1) {
            max-width: 320px;
            @media (max-width: 768px) {
                justify-content: center;
                    width: 300px;
                    margin: 0 auto;
                    * {
                        text-align: center;
                    }
                    
                }
        }

        .links {
            h4 {
                font-size: 32px;
                color: #000000;
                font-weight: 500;
                margin-bottom: 15px;
                @media (max-width: 768px) {
                    font-size: 25px;
                    text-align: center;
                }
            }
            .info {
                line-height: 24px !important;
                margin-top: 25px;
            }
            img {
                width: 250px;
                height: 50px;
                display: block;
                @media (max-width: 768px) {
                    width: 150px;
                    height: 30px;
                    margin: 20px auto;
                }
            }
            p {
                font-size: 16px;
                color: #000000B0;
                font-weight: 500;
            }
            a {
                font-size: 20px;
                color: #000000;
                font-weight: 500;
                transition: all .4s;
                padding: 0;
                margin: 0;
                line-height: 50px;
                &:hover {
                    color:  #001EFF;
                }
                @media (max-width: 768px) {
                    font-size: 15px;
                    text-align: center;
                }
            }
        }
    }
`